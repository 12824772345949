import React from "react";
import { BaseProps } from "../../../utils/types";
import { GridEventListener } from "@mui/x-data-grid";
import { useRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { getColumns } from "./columns";
import { Table } from "../../ui/Table/Table";
import { useGetMachineStats } from "../../../api/endpoints";

interface RevenueTableProps extends BaseProps {
    onRowClick: GridEventListener<"rowClick">;
}

export const RevenueTable = ({
    branches,
    from,
    to,
    onRowClick,
}: RevenueTableProps) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const { data: revenueSales, isLoading: revenueTransactionsLoading } = useRevenueTransactions({
        branches,
        from,
        to,
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
    });
    const { data: machineSales, isLoading: machineStatsLoading } = useGetMachineStats({
        branches,
        from,
        to,
    });

    const { columns, rows } = React.useMemo(() => {
        if (!revenueSales) {
            return { columns: [], rows: [] };
        }
        const machineMapping = machineSales?.machine_sales?.reduce(
            (acc: Record<number, any>, machine: any) => {
                acc[machine.id] = machine;
                return acc;
            },
            {} as Record<number, any>
        ) || {};

        const rows = revenueSales.data.map((item) => {
            const machine = machineMapping[item.machine_source_id];
            return {
                id: item.id,
                amount: item.amount,
                is_credit_card: item.is_credit_card,
                machine_name: machine ? machine.machine_name : item.machine_source_id,
                branch_name: machine ? machine.branch_name : item.branch_id,
                type: item.type,
                created_at: item.created_at,
            };
        });
        const columns = getColumns();
        return { columns, rows };
    }, [revenueSales, machineSales]);
    return (
        <Table
            columns={columns}
            rows={rows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalRows={revenueSales?.meta.total_count ?? 0}
            isLoading={revenueTransactionsLoading || machineStatsLoading}
            onRowClick={onRowClick}
            withToolbar={false}
        />
    );
};
