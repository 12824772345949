import { Form, redirect, useActionData, useSearchParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { Card, Flex } from "@tremor/react";
import { postPasswordReset } from '../../../api/endpoints/password-reset';
import { Callout } from "@tremor/react";

export async function action({ request }: { request: Request }) {
    let formData = await request.formData();
    const email = String(formData.get("email"));

    const data = await postPasswordReset(email);
    if (!data?.isSuccess) {
        return {
            message: "El correo electrónico no existe, intenta de nuevo.",
        };
    }
    return redirect("/password-reset?success=true");
}

export const PasswordReset = () => {
    const actionData = useActionData() as any;
    const [searchParams] = useSearchParams();
    const success = searchParams.get("success");

    return (
        <Flex
            flexDirection={"col"}
            className="app"
            style={{ height: "100vh" }}
            alignItems="center"
            justifyContent="start"
        >

            {actionData && actionData.message && (
                <div className="mx-auto max-w-lg space-y-6">
                    <Callout title="Error" color="red">
                        <p>{actionData.message}</p>
                    </Callout>
                </div>
            )}

            {success && (
                <div className="mx-auto max-w-lg space-y-6">
                    <Callout title="Exito" color="green">
                        <p>
                            Se ha enviado un correo con el enlace para restablecer la contraseña.
                            Revisa tu bandeja de entrada.
                        </p>
                    </Callout>
                </div>
            )}

            <Card className="w-4/5 md:w-96" style={{ marginTop: "50px" }}>
                <h1 className="text-3xl font-bold text-center">Restablecer contraseña</h1>
                <Form className="grid gap-5 mt-10" method="post">
                    <TextField
                        id="email"
                        label="Correo electrónico"
                        placeholder="Correo electrónico"
                        name="email"
                        type="email"
                    />
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                </Form>
            </Card>
        </Flex>
    );
};