import React from 'react';
import { AppBar, Toolbar, Typography } from "@mui/material";

export const TopBar = () => {
    return (
        <AppBar
            position="static"
            style={{ backgroundColor: "white", borderBottom: "1px solid #e5e7eb" }}
            elevation={0}
        >
            <Toolbar variant="dense">
                <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="#861b1e"
                    component="div"
                >
                    Mautra
                </Typography>
            </Toolbar>
        </AppBar>
    )
}