import { Skeleton } from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowIdGetter,
  GridToolbar,
} from "@mui/x-data-grid";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

interface TableProps {
  columns: GridColDef<any>[];
  rows: any[];
  isLoading: boolean;
  totalRows: number;
  paginationModel: GridPaginationModel;
  setPaginationModel: (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>
  ) => void;
  getRowId?: GridRowIdGetter<any>;
  paginationMode?: "client" | "server";
  withToolbar?: boolean;
  onRowClick?: GridEventListener<"rowClick">;
  initialState?: GridInitialStateCommunity;
}

export const Table = ({
  columns,
  rows,
  isLoading,
  totalRows,
  paginationModel,
  paginationMode = "server",
  setPaginationModel,
  getRowId,
  withToolbar = true,
  onRowClick,
  initialState,
}: TableProps) => {
  if (isLoading) {
    return (
      <div>
        {[...Array(10)].map((_, index) => (
          <Skeleton key={index} className="mx-2" variant="text" height={40} />
        ))}
      </div>
    );
  }
  return (
    <DataGrid
      slots={{
        toolbar: withToolbar ? GridToolbar : undefined,
      }}
      onRowClick={(event, details, api) => onRowClick && onRowClick(event, details, api)}
      loading={isLoading}
      rowCount={totalRows}
      paginationMode={paginationMode}
      pageSizeOptions={[10, 25, 50, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      columns={columns}
      rows={rows}
      getRowId={getRowId}
      rowHeight={50}
      style={{
        backgroundColor: "white",
      }}
      initialState={initialState}
    />
  );
};
