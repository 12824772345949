import { Outlet, redirect } from "react-router-dom";
import { APP_ROUTES } from "../../../routes";
import { TopBar } from "../components/TopBar";

export function loader() {
  const access_token = sessionStorage.getItem("itos_token");
  if (access_token !== null) {
    return redirect(APP_ROUTES.HOME);
  }
  return null;
}

export const UnprotectedRoutes = () => {
  return (
    <>
      <TopBar />
      <Outlet />
    </>

  )
};
