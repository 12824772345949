import { GridColDef } from "@mui/x-data-grid";
import { formatToMXN } from "../../../utils/money";

const COLUMNS: GridColDef[] = [
  {
    field: "branch_name",
    headerName: "Nombre de Sucursal",
    flex: 2,
  },
  {
    field: "cash_sitting",
    headerName: "Dinero en Kiosko",
    flex: 1,
    valueFormatter: (value: number) => formatToMXN(value),
  },
];

export const getColumns = () => COLUMNS;
