import React from "react";
import { useGetCustomers } from "../../../api/endpoints/get-customers";
import { CustomersTable } from "../CustomersTable";
import { useTimeFrame } from "../../../hooks/time-frame/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";
import { BasicTabs } from "../../ui/Tabs";
import { CustomersSummary } from "../CustomersSummary";

export const PageCustomers = () => {
  const { from, to } = useTimeFrame();
  const { branches, totalBranches } = useBranches();
  const [tab, setTab] = React.useState<number>(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const { data, isLoading } = useGetCustomers({
    branches,
    from,
    to,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });

  return (
    <div className="min-h-svh">
      <BasicTabs
        value={tab}
        onValueChange={(_, tab) => setTab(tab)}
        tabParam="customersTab"
        tabs={[
          {
            label: "Resumen",
            content: (
              <CustomersSummary
                branches={branches}
                from={from}
                to={to}
                totalBranches={totalBranches}
              />
            ),
          },
          {
            label: "Clientes",
            content: (
              <CustomersTable
                customers={data?.data}
                totalCount={data?.meta.total_count ?? 0}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                isLoading={isLoading}
                from={from}
                to={to}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
