import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface Customer {
  phone: number;
  last_movement: string | null;
  total_uses: number;
  amount_spent: number;
  branch_id: string;
  customer_id: string;
}

interface Response {
  data: Customer[];
  meta: {
    total_count: number;
    count: number;
    page: number;
  };
}

export const getCustomers = async ({
  branches,
  from,
  to,
  page,
  limit,
}: BaseProps) => {
  let url = "/api/v1/customers";
  url = addBaseURLParams(url, { from, to, branches });
  if (url === "/api/v1/customers") {
    url += `?page=${page}&limit=${limit}`;
  } else {
    url += `&page=${page}&limit=${limit}`;
  }

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetCustomerByIDProps extends BaseProps {
  customerID: number | string;
}

export const getCustomerByID = async ({
  branches,
  from,
  to,
  customerID,
}: GetCustomerByIDProps) => {
  let url = `/api/v1/customers/${customerID}`;
  url = addBaseURLParams(url, { from, to, branches });

  const response = await ax
    .get<{ data: Customer }>(url)
    .then((response) => response.data.data)
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetCustomers extends BaseProps {
  page?: number;
  limit?: number;
}
export const useGetCustomers = ({
  branches,
  from,
  to,
  page = 1,
  limit = 10,
}: GetCustomers) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-customers", branches, from, to, page, limit],
    queryFn: () => getCustomers({ branches, from, to, page, limit }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data, isLoading };
};

export const useGetCustomerByID = ({
  branches,
  from,
  to,
  customerID,
}: GetCustomerByIDProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-customer-by-ID", customerID],
    queryFn: () => getCustomerByID({ branches, from, to, customerID }),
  });

  return { data, isLoading };
};
