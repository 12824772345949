import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface RevenueBranch {
  branch_id: number;
  branch_name: string;
  total_sales?: number;
  total_movements?: number;
  cash_sitting?: number;
}
interface RevenueBranchesResponse {
  data: RevenueBranch[];
}

export const getRevenueBranches = async ({
  from,
  to,
  branches,
  type,
}: RevenueBranchesProps) => {
  let url = "/api/v1/revenue/branches";
  url = addBaseURLParams(url, { from, to, branches });
  if (type) {
    url += `&type=${type}`;
  }
  const response = await ax
    .get<RevenueBranchesResponse>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

interface RevenueBranchesProps extends BaseProps {
  type?: "sales" | "current_cash";
}

export const useRevenueBranches = ({
  branches,
  from,
  to,
  type,
}: RevenueBranchesProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["revenue-branches", from, to, branches, type],
    queryFn: () => getRevenueBranches({ branches, from, to, type }),
  });
  if (!data) {
    return { data: undefined, isLoading };
  }
  if (type === "current_cash") {
    return {
      data: data.data.filter((item) => item.cash_sitting !== 0),
    };
  }
  return { data: data.data, isLoading };
};
