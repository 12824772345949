import React from "react";
import { useAllRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { BaseProps } from "../../../utils/types";
import { LineChart } from "@mui/x-charts/LineChart";
import { SECONDARY_COLOR, TERTIARY_COLOR } from "../../../utils/colors";
import { SLOT_PROPS } from "../../../utils/charts";
import { GraphContainer } from "../../ui/GraphContainer";

interface CashVSCreditByDayProps extends BaseProps { }

export const CashVSCreditByDay = ({
  branches,
  from,
  to,
}: CashVSCreditByDayProps) => {
  const { data, isLoading } = useAllRevenueTransactions({ branches, from, to });
  const { keys, values } = React.useMemo(() => {
    if (!data || isLoading) {
      return { keys: [], values: [] };
    }
    const keys = data.data.map(item => item.sale_date);
    const values = data.data.map(item => ({
      cashAmount: item.cash_amount,
      creditAmount: item.credit_amount,
    }));
    return { keys, values };
  }, [data, isLoading]);

  return (
    <GraphContainer>
      <h3 className="text-base font-bold">Método de pago por día</h3>
      <LineChart
        slotProps={SLOT_PROPS}
        xAxis={[
          {
            scaleType: "point",
            data: keys,
          },
        ]}
        series={[
          {
            label: "Efectivo",
            data: values.map((v) => v.cashAmount),
            area: true,
            valueFormatter: (v) => `$${v?.toLocaleString()}`,
          },
          {
            label: "Tarjeta",
            data: values.map((v) => v.creditAmount),
            area: true,
            valueFormatter: (v) => `$${v?.toLocaleString()}`,
          },
        ]}
        grid={{
          horizontal: true,
        }}
        height={350}
        colors={[SECONDARY_COLOR, TERTIARY_COLOR]}
      />
    </GraphContainer>
  );
};
