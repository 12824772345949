import React from "react";
import { getColumns } from "./columns";
import { Table } from "../../ui/Table/Table";
import { RevenueBranch } from "../../../api/endpoints/get-revenue-branches";

interface MoneyInKioskoTableProps {
    data?: RevenueBranch[]
    isLoading?: boolean
}

export const MoneyInKioskoTable = ({
    data,
    isLoading,
}: MoneyInKioskoTableProps) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const columns = React.useMemo(() => getColumns(), []);

    const rows = React.useMemo(() => {
        if (!Array.isArray(data)) return [];
        const startIndex = paginationModel.page * paginationModel.pageSize;
        const endIndex = startIndex + paginationModel.pageSize;
        return data.slice(startIndex, endIndex).map((row) => ({
            ...row,
            id: row.branch_id,
        }));
    }, [data, paginationModel]);

    return (
        <div className="bg-white p-4 border-[1px] rounded-[4px] border-[#d2d6dc] h-fit">
            <h1 className="text-black font-bold pb-8">
                Dinero en Kiosko por tienda
            </h1>
            <Table
                columns={columns}
                rows={rows}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                totalRows={data?.length ?? 0}
                isLoading={isLoading ?? false}
                withToolbar={false}
            />
        </div>
    );
};
