import React from "react";
import { useTimeFrame } from "../../../hooks/time-frame/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";
import { BasicTabs } from "../../ui/Tabs";
import { RevenueSalesSummary } from "../RevenueSalesSummary";
import { RevenueTable } from "../RevenueTable";

export const PageRevenue = () => {
  const { from, to } = useTimeFrame();
  const { branches, totalBranches } = useBranches();
  const [tab, setTab] = React.useState<number>(0);

  const onRowClick = () => { };

  return (
    <div className="w-auto">
      <BasicTabs
        tabs={[
          {
            label: "Resumen",
            content: (
              <RevenueSalesSummary branches={branches} totalBranches={totalBranches} from={from} to={to} />
            ),
          },
          {
            label: "Movimientos",
            content: (
              <RevenueTable branches={branches} from={from} to={to} onRowClick={onRowClick} />
            ),
          },
        ]}
        tabParam="revTab"
        value={tab}
        onValueChange={(_, tab) => setTab(tab)}
      />
    </div>
  );
};
