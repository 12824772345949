import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { formatToMXN } from "../../../utils/money";
import { Movement } from "../../../api/endpoints/get-revenue-transactions";
import { toMexicoTimeZone } from "../../../utils/date";

const COLUMNS: GridColDef<Movement>[] = [
    {
        field: "amount",
        headerName: "Monto",
        flex: 1,
        valueFormatter: (value: number) => formatToMXN(value),
    },
    {
        field: "is_credit_card",
        headerName: "Tipo de pago",
        flex: 1,
        renderCell: (params: GridRenderCellParams<Movement>) =>
            params.value ? "Tarjeta" : "Efectivo",
    },
    {
        field: "balance",
        headerName: "Tipo",
        flex: 1,
        renderCell: (params: GridRenderCellParams<Movement>) =>
            params.value === "balance" ? "Saldo" : "Uso de máquina",
    },
    {
        field: "created_at",
        headerName: "Fecha",
        flex: 1,
        valueFormatter: (value: string) =>
            toMexicoTimeZone(value),
    }
];

export const getColumns = () => COLUMNS;
