export const APP_ROUTES = {
  BASE: "/",
  HOME: "/app",
  MACHINES: "/app/machines",
  CUSTOMERS: "/app/customers",
  SETTINGS: "/app/settings",
};

export const APP_UNPROTECTED_ROUTES = {
  LOGIN: "/",
  CHANGE_PASSWORD: "/change-password",
  PASSWORD_RESET: "/password-reset",
};
