import { instance as ax } from "../setup";

export const postPasswordReset = async (email: string) => {
  const url = "/password-reset";

  const payload = { email };

  try {
    const response = await ax.post(url, payload, {
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      return {
        isSuccess: true,
        message:
          response.data.message ||
          "Se ha enviado un correo con el enlace para restablecer la contraseña.",
      };
    }
    return {
      isSuccess: false,
      message: response.data.message || "Error al enviar el correo.",
    };
  } catch (error: any) {
    console.error("Error in postPasswordReset:", error);
    return {
      isSuccess: false,
      message:
        error.response?.data?.message ||
        "Error al enviar el correo electrónico.",
    };
  }
};
