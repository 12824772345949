import { useGetCustomerByID } from "../../../api/endpoints/get-customers";
import { SidePanel } from "../../SidePanel/SidePanel";
import { NumberCard } from "../../ui/NumberCard";

interface CustomerDetailProps {
  isOpen: boolean;
  onClose: () => void;
  branch: string[];
  customerID: string;
  from: string;
  to: string;
}

export const CustomerDetail = ({
  isOpen,
  onClose,
  branch,
  customerID,
  from,
  to,
}: CustomerDetailProps) => {
  const { data: customer, isLoading } = useGetCustomerByID({ branches: branch, to, from, customerID });
  return (
    <SidePanel
      onClose={onClose}
      title="Información del cliente"
      isOpen={isOpen}
    >
      <div className="grid grid-cols-2 gap-2">
        <NumberCard
          title="Teléfono"
          amount={customer?.phone ?? "N/A"}
          isLoading={isLoading}
        />
        <NumberCard
          title="Último movimiento"
          amount={
            customer?.last_movement
              ? new Date(customer.last_movement).toLocaleString("es-ES", {
                dateStyle: "long",
                timeStyle: "short",
              })
              : "N/A"
          }
          isLoading={isLoading}
        />
        <NumberCard
          title="Total gastado"
          amount={customer?.amount_spent.toString() ?? "N/A"}
          isLoading={isLoading}
        />
        <NumberCard
          title="Total de usos"
          amount={customer?.total_uses.toString() ?? "N/A"}
          isLoading={isLoading}
        />
      </div>
    </SidePanel>
  );
};
