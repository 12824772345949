import { instance as ax } from "../setup";

export const postChangePassword = async (token: string, password: string) => {
    const url = `/change-password?token=${encodeURIComponent(token)}`;

    const payload = { password };

    try {
        const response = await ax.post(url, payload, {
            headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
            return {
                isSuccess: true,
                message:
                    response.data.message ||
                    "La contraseña ha sido cambiada exitosamente.",
            };
        }
        return {
            isSuccess: false,
            message: response.data.message || "Error al cambiar la contraseña.",
        };
    } catch (error: any) {
        console.error("Error in postChangePassword:", error);
        return {
            isSuccess: false,
            message:
                error.response?.data?.message ||
                "Error al cambiar la contraseña.",
        };
    }
};