import { useState, useEffect } from "react";
import { Form, redirect, useActionData } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { Card, Flex } from "@tremor/react";
import { Callout } from "@tremor/react";
import { postChangePassword } from "../../../api/endpoints/change-password";

export async function action({ request }: { request: Request }) {
    const formData = await request.formData();
    const password = String(formData.get("password"));

    if (!password) {
        return { message: "La nueva contraseña es requerida." };
    }

    const url = new URL(request.url);
    const token = url.searchParams.get("token");
    if (!token) {
        return { message: "El token de restablecimiento es requerido." };
    }

    const data = await postChangePassword(token, password);
    if (!data?.isSuccess) {
        return { message: data.message || "Error al cambiar la contraseña, intenta de nuevo." };
    }
    return redirect("/app", {});
}

export const ChangePassword = () => {
    const actionData = useActionData() as any;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [clientError, setClientError] = useState("");

    useEffect(() => {
        if (password && confirmPassword && password !== confirmPassword) {
            setClientError("Las contraseñas no coinciden");
        } else {
            setClientError("");
        }
    }, [password, confirmPassword]);

    return (
        <Flex
            flexDirection="col"
            className="app"
            style={{ height: "100vh" }}
            alignItems="center"
            justifyContent="start"
        >
            {actionData && actionData.message && (
                <div className="mx-auto max-w-lg space-y-6">
                    <Callout title="Error" color="red">
                        <p>{actionData.message}</p>
                    </Callout>
                </div>
            )}

            {clientError && (
                <div className="mx-auto max-w-lg space-y-6">
                    <Callout title="Error" color="red">
                        <p>{clientError}</p>
                    </Callout>
                </div>
            )}

            <Card className="w-4/5 md:w-96" style={{ marginTop: "50px" }}>
                <h1 className="text-3xl font-bold text-center">Cambiar Contraseña</h1>
                <Form className="grid gap-5 mt-10" method="post">
                    <TextField
                        id="password"
                        label="Nueva Contraseña"
                        name="password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        id="confirmPassword"
                        label="Confirmar Contraseña"
                        name="confirmPassword"
                        type="password"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button type="submit" variant="contained" disabled={!!clientError}>
                        {" "}
                        Submit{" "}
                    </Button>
                </Form>
            </Card>
        </Flex>
    );
};