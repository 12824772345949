import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface Movement {
  amount: number;
  fetched_at: string;
  created_at: string;
  data: string;
  is_credit_card: boolean;
  id: number;
  branch_id: number;
  type: string;
  machine_source_id: number;
  action: string;
}
export interface RevenueTransactions {
  sale_date: string;
  credit_amount: number;
  cash_amount: number;
}
interface Response {
  data: Movement[];
  meta: {
    total_count: number;
    count: number;
    page: number;
  };
}
interface RevenueTransactionsResponse {
  data: RevenueTransactions[];
  meta: {
    branch_ids: string;
  };
}

export const getRevenueTransactions = async ({
  from,
  to,
  branches,
  page,
  limit,
}: BaseProps) => {
  let url = "/api/v1/movements";
  url = addBaseURLParams(url, { from, to, branches });
  if (url === "/api/v1/movements") {
    url += `?page=${page}&limit=${limit}`;
  } else {
    url += `&page=${page}&limit=${limit}`;
  }

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

export const getAllRevenueTransactions = async ({
  from,
  to,
  branches,
}: BaseProps) => {
  let url = "/api/v1/revenue/transactions";
  url = addBaseURLParams(url, { from, to, branches });
  const response = await ax
    .get<RevenueTransactionsResponse>(url)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error fetching all revenue transactions:", error);
      return null;
    });
  return response;
};

interface RevenueTransactionsProps extends BaseProps {
  page?: number;
  limit?: number;
}

export const useRevenueTransactions = ({
  branches,
  from,
  to,
  page = 1,
  limit = 10,
}: RevenueTransactionsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["revenueTransactions", from, to, branches, page, limit],
    queryFn: () => getRevenueTransactions({ branches, from, to, page, limit }),
  });
  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data, isLoading };
};

export const useAllRevenueTransactions = ({
  branches,
  from,
  to,
}: BaseProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["allRevenueTransactions", from, to, branches],
    queryFn: () => getAllRevenueTransactions({ branches, from, to }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data, isLoading };
};
