import { Drawer } from "@mui/material";
import { DatePicker } from "../ui/DatePicker";
import { useTimeFrame } from "../../hooks/time-frame/use-time-frame";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

interface SidePanelProps {
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    sm?: string;
    md?: string;
}
export const SidePanel: React.FC<SidePanelProps> = ({
    children,
    isOpen,
    onClose,
    title,
    sm = "100%",
    md = "60%",
}) => {
    const { timeFrame, setTimeFrame } = useTimeFrame();
    return (
        <Drawer
            anchor="right"
            open={isOpen}
            sx={{
                "& .MuiDrawer-paper": {
                    width: {
                        sm: sm,
                        md: md
                    },
                },
            }}
            onClose={onClose}
        >
            <div className="md:flex md:flex-col md:items-end my-2 px-4">
                <DatePicker
                    key={"machine-detail"}
                    value={timeFrame}
                    onValueChange={setTimeFrame}
                    className="mb-2"
                />
                <Button variant="outlined" onClick={onClose} size="small">
                    Cerrar
                </Button>
            </div>
            <Divider />
            <section className="px-3 pt-2">
                <h2 className="font-bold mb-2">{title}</h2>
                {children}
            </section>
        </Drawer>
    )
}